<template>
  <div class="w-screen h-full bg-black md:hidden">
    <div class="header fixed top-0 w-full z-10" ref="header">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
    </div>
    <div class="fake-header opacity-0 top-0 w-full">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
    </div>

    <div class="event_result bg-buyBackground flex" :style="fullHeight" v-show="this.fromLineId == undefined">
      <div class="m-auto">
        <div class="heading flex justify-center items-center flex-wrap">
          <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold" v-show="this.tickets <= 0">
            您沒有多餘的票可以分享
          </h3>
          <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold" v-show="this.tickets > 0">
            您有 {{ this.tickets }} 張票可以分享
          </h3>
        </div>
        <hr class="w-1/5 text-white mx-auto mt-8 border-2" v-show="this.tickets > 0">
        <div class="form mx-4 mt-10 before" autocomplete="off">
          <p class="text-white text-lg tracking-widest text-center" v-show="this.tickets > 0">
            請點選以下按鈕進行分票！
          </p>
          <div class="flex justify-center mt-10 pb-10" >
            <div class="hearts flex justify-end items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
            <button 
              class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-5 py-2" 
              @click="shareTicket"
               v-show="this.tickets > 0"
            >
              立即分票
            </button>
            <button 
              class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-5 py-2" 
              @click="closeWindow"
               v-show="this.tickets <= 0"
            >
              關閉視窗
            </button>
            <div class="hearts flex justify-start items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="event_result bg-buyBackground flex py-10" :style="fullHeight" v-show="this.fromLineId != undefined">
      <div class="m-auto">
        <div class="heading flex justify-center items-center flex-wrap">
          <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold">
            領取票券
          </h3>
        </div>
        <hr class="w-1/5 text-white mx-auto mt-8 border-2" v-show="this.tickets > 0">
        <div class="form mx-4 mt-10 before" autocomplete="off">
            <!-- 姓名 -->
            <input 
            class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputActive"
            placeholder="姓名"
            v-model="form.name"
            autocomplete="new-name"
            >
            <!-- 性別 -->
            <div class="gender-selector flex justify-between my-5">
            <button class="text-xl font-Noto-Sans border-2 bg-secondBlack rounded-lg text-center w-1/2 mr-5 py-5 border-white" :class="{
                'text-gray': (form.male == false),
                'text-white': (form.male == true),
            }" @click="changeGender(true)">
                生理男
            </button>
            <button class="text-xl font-Noto-Sans border-2 bg-secondBlack rounded-lg text-center w-1/2 ml-5 py-5 border-white" :class="{
                'text-gray': (form.male == true),
                'text-white': (form.male == false),
            }" @click="changeGender(false)">
                生理女
            </button>
            </div>
            <!-- 聯絡電話 -->
            <input 
            class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive"
            placeholder="聯絡電話"
            v-model="form.phone"
            autocomplete="new-phone"
            >
            <!-- 電子郵件 -->
            <input 
            class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive"
            placeholder="電子郵件"
            v-model="form.email"
            autocapitalize="new-email"
            >
            <!-- 備註 -->
            <p class="text-white text-sm leading-loose tracking-widest">
            ＊為享有完整體驗，請填寫你方便收取包裹的地址，我們將在活動開始前，把「微醺愛戀不出包」寄給你
            </p>
            <div class="flex justify-center mt-10 pb-10">
            <div class="hearts flex justify-end items-center w-1/4 px-5">
                <img class="w-1/3" src="@/assets/heart.png" />
            </div>
            <button class="mx-auto bg-white text-buyBackground shadow-inputActive rounded-lg text-lg px-10 py-2" @click="getTicket">
                立即領取
            </button>
            <div class="hearts flex justify-start items-center w-1/4 px-5">
                <img class="w-1/3" src="@/assets/heart.png" />
            </div>
            </div>
        </div>
      </div>
    </div>
    
    <div class="footer fixed bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3" ref="footer">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
    <div class="fake-footer opacity-0 fixed bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
  </div>
</template>

<script>
import liff from '@line/liff';
import stores from './stores.json'
import axios from 'axios';

export default {
  name: 'App',
  data(){
    return {
      stores,
      headerHeight: 0,
      footerHeight: 0,
      tickets: 0,
      fromLineId: '',
      form: {
        name: "",
        male: true,
        phone: "",
        email: "",
      },
    }
  },
  computed: {
    fullHeight: function(){
      let windowHeight = window.innerHeight;
      return {
        'min-height': `${windowHeight - this.headerHeight - this.footerHeight}px`,
      }
    }
  },
  methods: {
    changeGender(isMale){
      this.form.male = isMale;
    },
    shareTicket(){
      liff.shareTargetPicker([{
        type: "flex",
        altText: "2022 酒精路跑巡禮",
        contents: {
          "type": "bubble",
          "hero": {
            "type": "image",
            "url": "https://hwstud.io/img/main.c5dfeb92.png",
            "size": "full",
            "aspectRatio": "2:1",
            "aspectMode": "fit",
            "action": {
              "type": "uri",
              "uri": "http://linecorp.com/"
            },
            "backgroundColor": "#000000",
            "margin": "lg"
          },
          "body": {
            "type": "box",
            "layout": "vertical",
            "contents": [
              {
                "type": "text",
                "text": "您收到了一份禮物！",
                "weight": "bold",
                "size": "lg",
                "align": "center"
              }
            ]
          },
          "footer": {
            "type": "box",
            "layout": "vertical",
            "spacing": "sm",
            "contents": [
              {
                "type": "button",
                "style": "primary",
                "height": "sm",
                "action": {
                  "type": "uri",
                  "label": "立即領取",
                  "uri": `https://liff.line.me/1656804955-M4125VRe?from=${liff.getContext().userId}`
                },
                "color": "#ed1e78"
              }
            ],
            "flex": 0
          }
        }
      }])
        .then((result) => {
          if (result.status == 'success') {
            // 分享完成 
          }
        })
    },
    closeWindow(){
      liff.closeWindow()
    },
    checkPhone(){
      const phoneNumber = this.form.phone;
      if(/^[0-9]{10}$/.test(phoneNumber)){
        return true;
      }else{
        alert("請確認手機號碼填寫正確！")
        return false;
      }
    },
    checkEmail(){
      const email = this.form.email;
      if(/[\w-]+@([\w-]+\.)+[\w-]+/.test(email)){
        return true;
      }else{
        alert("請確認電子郵件填寫正確！")
        return false;
      }
    },
    getTicket(){
      liff.getFriendship()
        .then((data) => {
          if(data.friendFlag){
            let postData = {
              name: this.form.name,
              isMale: (this.form.male == true) ? 1 : 0,
              email: this.form.email,
              phone: this.form.phone,
            }
            if (this.form.name.trim() == '') {
              alert("請填寫姓名");
            }else{
              if(this.checkPhone()){
                if (this.checkEmail()) {
                  axios
                    .post(`https://api.hwstud.io/v2/getTicket.php?from=${this.fromLineId}&lineId=${liff.getContext().userId}`, postData)
                    .then((result) => {
                      let response = result.data;
                      if(response.status == 0){
                        alert("領票成功！")
                        window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
                      }else if(response.status == -2){
                        alert("您已經有票券！")
                      }else if(response.status == -1){
                        alert("沒有剩餘票券可以領取！");
                      }else{
                        alert("領取票券時發生錯誤！")
                      }
                    })
                }
              }
            }
          }else{
            alert("請先加入好友！")
            window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
          }
        })
    }
  },
  created(){
    window.onload = function(){
      document.addEventListener('touchstart', function(e) {
        if(e.touches.length > 1){
          e.preventDefault();
        }
      })
      document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
      })
    }
  },
  mounted(){
    this.headerHeight = this.$refs.header.clientHeight;
    this.footerHeight = this.$refs.footer.clientHeight;

    
    this.fromLineId = this.$route.query.from;

    liff
      .init({
        liffId: '1656804955-M4125VRe', // Use own liffId
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        }else{
          if (this.fromLineId != undefined) {
            axios
              .get(`https://api.hwstud.io/v2/validate-ticket.php?from=${this.fromLineId}`)
              .then((result) => {
                let response = result.data;
                if (response.status != 0) {
                  alert("沒有剩餘票券可以領取！")
                  liff.closeWindow();
                }else{
                  liff.getFriendship()
                    .then((data) => {
                      if(!data.friendFlag){
                        alert("請先加入好友！")
                        window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
                      }
                    })
                }
              })
          }else{
            axios
              .get(`https://api.hwstud.io/v2/tickets.php?userId=${liff.getContext().userId}`)
              .then((result) => {
                let response = result.data;
                this.tickets = response;
              })
          }
        }
      })

    
  }
}
</script>

<style>

</style>
